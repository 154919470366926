import React, { useContext } from 'react'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import { Link, navigate } from 'gatsby'
import StoreContext from '../../context/StoreContext'

const GemWearWithContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  @media (max-width: 768px) {
    text-align: center;
    position: static;
    padding-bottom: 30px;
  }
`

const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 120px;
  width: 500px;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`

const Thumbnail = styled.div`
  width: auto;
  height: 100%;
  margin-left: 20px;
  width: 100px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: calc(33vw - 40px);
    margin-left: 10px;
    margin-right: 10px;
    &:nth-child(4) {
      display: none;
    }
    &:nth-child(5) {
      display: none;
    }
    &:nth-child(6) {
      display: none;
    }
  }
`

const ShopTitle = styled.h3`
  text-align: right;
  margin-bottom: 14px;
  text-transform: none;
  font-size: 11px;
  font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
  font-weight: 300;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 13px;
    line-height: 18px;
  }
  cursor: pointer;
  a {
    text-decoration: none;
  }
`

const GemWearWith = (props) => {

    const { tag } = props;
    const url = '/gemstones/'+tag.toLowerCase();
    const context = useContext(StoreContext)
    const { gemOpen } = context
    const toggleGem = (visible,name) => {
      context.toggleGem(visible, name);
    }

    return (
      <StaticQuery
        query={graphql`
        query WearWithGemPopupQuery {
          products: allShopifyProduct(
            filter: {tags: {ne: "Hidden on /shop"}}, sort: { fields: [title], order: ASC }
          ) {
            edges {
              node {
                id
                handle
                title
                tags
                description
                productType
                variants {
                  shopifyId
                  title
                  price
                  availableForSale
                }
                images {
                  id
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 910) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
        `}
        render={({ products }) => (
          <>
            <GemWearWithContainer>
              <ShopTitle onClick={() => (toggleGem(false, gemOpen.name), navigate(url))}>Shop {tag}</ShopTitle>
              <ThumbnailContainer>
                {
                products.edges
                .filter(product => product.node.tags !== undefined)
                .filter(product => product.node.title.toString().toLowerCase().includes(tag.toLowerCase()))
                .slice(0,5)
                .sort(() => 0.5 - Math.random())
                .map(product => {
                  return(
                    <Thumbnail key={product.node.title}>
                      <div onClick={() => (toggleGem(false, gemOpen.name), navigate(product.node.handle))}>
                        <Image alt={product.node.title} fluid={product.node.images[0].localFile.childImageSharp.fluid} />
                      </div>
                    </Thumbnail>
                  )
                })}
              </ThumbnailContainer>
            </GemWearWithContainer>
          </>
        )}
      />
    );

}

export default GemWearWith;

export const Collections = [
  {
    name: 'blessing-collection',
    inmenu: true,
    title: 'Blessing',
    url: '/collections/blessing-collection',
    body: 'Blessings plays on the deep-rooted meanings behind semi-precious and precious stones and looks to the physical and spiritual benefits that stones can bring to the wearer. Theodora is fascinated by the talismanic and each stone that she uses in her designs has its own distinctive symbolism. All of the gemstones in her designs are sourced, cut and polished in Jaipur and include emerald which signify rewards and success; diamonds and white sapphire for love and clarity; amethysts for protection; blue sapphire for peace; yellow sapphire for joy and rubies for health and protection.',
    shop_url: '/collections/blessing-collection/shop',
    intro_image: 'blessing-positivity-earrings.jpg',
    intro_link: '/emerald-blessings-pendant-necklace',
    images: [
      {
        image: 'hoop-new.jpg',
        link: '/diamond-love-hoop-earrings',
      },
      {
        image: 'model1.jpg',
        link: '/diamond-love-hoop-earrings',
      },
      {
        image: '2.jpg',
        link: '/positivity-earrings',
      },
      {
        image: 'bangle.jpg',
        link: '/ruby-bangle',
      },
      {
        image: 'model2.jpg',
        link: '/ruby-hoop-earrings',
      },
      {
        image: '1.jpg',
        link: '/ruby-hoop-earrings',
      },
    ]
  },
  {
    name: 'camille-charriere-x-theo',
    inmenu: false,
    title: 'Camille Charriere x Theo',
    url: '/collections/camille-charriere-x-theo',
    body: 'For Summer 2019 Theodora has collaborated with friend and leading fashion influencer Camille Charriere on a capsule collection which combines Theodora’s signature design aesthetic with Camille’s laid-back Parisian style. The collection comprises of simple chain necklaces and anklets in varying thicknesses, a pair of Theodora’s signature hoop earrings and the ‘Paris’ pendant – named after Camille’s home city.  ‘I love Camille’s very natural, un-curated approach to fashion. She wears her jewellery so effortlessly but with such innate confidence. We have wanted to work together for a while and this collaboration was born from a shared desire to create the ultimate capsule jewellery collection that is simple but statement; timeless but contemporary; relaxed but chic. I love that the pieces can be styled up or down; worn by themselves or layered up.” Theodora Warre',
    shop_url: '/collections/camille-charriere-x-theo/shop',
    intro_image: '1.jpg',
    intro_link: '/paris-pendant-necklace',
    images: [
      {
        image: '2.jpg',
        link: '/twisted-band-ring',
      },
      {
        image: '3.jpg',
        link: '/medium-twisted-hoop-earrings',
      },
      {
        image: '4.jpg',
        link: '/collections/camille-charriere-x-theo/shop',
      },
      {
        image: '5.jpg',
        link: '/zircon-pinky-ring',
      },
      {
        image: '6.jpg',
        link: '/chunky-chain-necklace',
      },
      {
        image: '7.jpg',
        link: '/chunky-chain-necklace',
      },
    ]
  },
  {
    name: 'fortune-teller',
    inmenu: true,
    title: 'Fortune Teller',
    url: '/collections/fortune-teller',
    body: 'Theodora’s ‘Fortune Teller’ collection explores the talismanic nature of jewellery and the enduring sense of protection, hope and security that it can bring to the wearer. Designed with a free-spirited traveller in mind, ‘Fortune Teller’ is a celebration of the sense of exploration and joie de vivre that are so integral to Theodora’s design aesthetic. As with all of Theodora’s designs, ‘Fortune Teller’ is designed to be layered freely.',
    shop_url: '/collections/fortune-teller/shop',
    intro_image: '1.jpg',
    intro_link: '/st-christopher-pendant-necklace',
    images: [
      {
        image: '2.jpg',
        link: '/large-gypsy-hoop-earrings',
      },
      {
        image: '3.jpg',
        link: '/large-gypsy-hoop-earrings',
      },
      {
        image: '4.jpg',
        link: '/chalcedony-rhodolite-garnet-tooth-earrings',
      },
      {
        image: '5.jpg',
        link: '/chalcedony-rhodolite-garnet-tooth-earrings',
      },
      {
        image: '6.jpg',
        link: '/tiger-eye-amethyst-tooth-earrings',
      },
      {
        image: '7.jpg',
        link: '/tiger-eye-amethyst-tooth-earrings',
      },
    ]
  },
  {
    name: 'moonchild',
    title: 'Moonchild',
    inmenu: true,
    url: '/collections/moonchild',
    body: '‘Moon Child’ draws on Theodora’s love of the celestial and ethereal – from the iridescent rainbow moonstone which features so frequently in her designs to the moon, stars and compasses which she hopes will act as an inner guide for the wearer.',
    shop_url: '/collections/moonchild/shop',
    intro_image: '1.jpg',
    intro_link: '/lapis-lazuli-star-pinky-ring',
    images: [
      {
        image: '2.jpg',
        link: '/amethyst-spike-hoop-earrings',
      },
      {
        image: '3.jpg',
        link: '/amethyst-spike-hoop-earrings',
      },
      {
        image: '4.jpg',
        link: '/amethyst-garnet-crystal-sun-ring',
      },
      {
        image: '5.jpg',
        link: '/amethyst-garnet-crystal-sun-ring',
      },
      {
        image: '6.jpg',
        link: '/rainbow-moonstone-zircon-hoop-earrings',
      },
      {
        image: '7.jpg',
        link: '/rainbow-moonstone-zircon-hoop-earrings',
      },
    ]
  },
  {
    name: 'shapeshifter',
    title: 'Shapeshifter',
    inmenu: true,
    url: '/collections/shapeshifter',
    body: 'Shapeshifter is a celebration of both colour, the simplicity of shape and form and the way in which stones can take on another look completely when cut and set in different shapes. The collection features Emeralds and Diamonds set within Amethysts and Crystals, each stone has been meticulously carved and shaped before being set directly into another, adding lustre and a sense of volume to each design. This geometric collection of earrings, rings, bracelets and necklaces features a variety of simple, striking stone cuts including baguettes, triangles, hearts, squares and trillions. Layer one of the more statement styles  with a pair of dainty triangle cuff hoops.',
    shop_url: '/collections/shapeshifter/shop',
    intro_image: '1.jpg',
    intro_link: '/collections/shapeshifter/shop',
    images: [
      {
        image: '2.jpg',
        link: '/collections/shapeshifter/shop',
      },
      {
        image: '3.jpg',
        link: '/collections/shapeshifter/shop',
      },
      {
        image: '4.jpg',
        link: '/collections/shapeshifter/shop',
      },
      {
        image: '5.jpg',
        link: '/collections/shapeshifter/shop',
      },
      {
        image: '6.jpg',
        link: '/collections/shapeshifter/shop',
      },
      {
        image: '7.jpg',
        link: '/collections/shapeshifter/shop',
      },
    ]
  },
]

import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components'
import { withPrefix } from 'gatsby'
import StoreContext from '../../context/StoreContext'
import ContainerText from '../container_text'
import { Container, Row, Col } from 'styled-bootstrap-grid2'
import { Gemstones } from '../../utils/gemstones'
import Close from '../../images/x.png'
import GemWearWith from '../../components/gem_wearwith'

const GemPopupContainer = styled.div`
  position: fixed;
  transition:all 0.3s ease-in-out;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  opacity: ${props => props.visible ? '1' : '0'};
  z-index: ${props => props.visible ? '100000' : '-1'};
  height: ${props => props.visible ? '100%' : '0%'};
  overflow: ${props => props.visible ? 'visible' : 'hidden'};
  background-color: #fff;
  @media (max-width: 768px) {
    height: auto;
    overflow: auto;
    max-width: 100vw;
  }
`
const CloseImage = styled.img`
  width: 18px;
  height: 20px;
  position: fixed;
  top: 40px;
  right: 40px;
`

const GemTitle = styled.h3`
  text-transform: none;
  font-size: 14px;
  font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
  font-weight: 300;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
`
const DescriptionTitle = styled.h3`
  text-transform: none;
  font-size: 14px;
  font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
  font-weight: 300;
  margin-bottom: 12px;
  text-rendering: optimizeLegibility;
`
const DescriptionText = styled.p`
  font-size: 11px;
  line-height: 14px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 18px;
  }
`

const Heading = styled.h3`
  text-transform: uppercase;
  color: #3C3C3B;
  font-size: 11px;
  font-style: italic;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 18px;
  }
`

const Divider = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 0px !important;
  }
  hr {
    height: 1px;
    display: block;
    border: 0;
    border-top: 1px solid #CFC9C2;
    margin: 0;
    padding: 0;
  }
`

const GemContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 100vh;
  @media (max-width: 768px) {
    max-height: none;
    flex-direction: column;
    max-width: 100vw;
  }
`

const GemColumn1 = styled.div`
  display: flex;
  flex-basis: 30%;
  justify-content: center;
  align-self: flex-start;
  @media (max-width: 768px) {
    flex-basis: 100%;
    order: 3;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100vw;
  }
  table {
    width: 100%;
    th {
      width: 33%;
      text-align: center;
      color: #3C3C3B;
      font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
      font-weight: 300;
    }
    td {
      color: #9C978F;
      width: 33%;
      text-align: center;
      vertical-align: top;
      font-size: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`
const GemColumn2 = styled.div`
  display: flex;
  flex-basis: 40%;
  justify-content: center;
  align-self: center;
  text-align: center;
  @media (max-width: 768px) {
    flex-basis: 100%;
    order: 1;
  }
  h2 {
    margin-top: 20px;
    font-style: italic;
    font-size: 15px;
  }
  video {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 280px;
  }
`
const GemColumn3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  flex-basis: 30%;
  @media (max-width: 768px) {
    flex-basis: 40%;
    order: 2;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 80px;
  }
  h3 {
    padding-bottom: 10px;
  }
  p {
    padding-right: 0px;
  }
`

const GemPopup = () => {

  const context = useContext(StoreContext)
  const { gemOpen } = context
  const toggleGem = (visible,name) => {
    context.toggleGem(visible, name);
  }

  const { prismicgemstones } = useStaticQuery(graphql`
    query {
      prismicgemstones:
      allPrismicGem {
        edges {
          node {
            slugs
            data {
              birthstone {
                text
              }
              colour {
                text
              }
              composition {
                text
              }
              fracture {
                text
              }
              hardness {
                text
              }
              image {
                url
                localFile {
                  absolutePath
                }
              }
              info_body {
                text
              }
              info_title {
                text
              }
              lustre {
                text
              }
              origin {
                text
              }
              still_image {
                url
                localFile {
                  absolutePath
                }
              }
              title {
                text
              }
              video {
                url
              }
            }
          }
        }
      }
    }
  `);

  let Gem;

  prismicgemstones.edges.map(g => {
    if(g.node.data.title.text == gemOpen.name) {
      Gem = g;
    }
  })

  if(Gem) {

    // const video = require(`../../videos/${Gem.video_light}`);

    return (
      <GemPopupContainer name={gemOpen.name} visible={gemOpen.visible}>
        <CloseImage src={Close} alt="close" onClick={() => toggleGem(false, gemOpen.name)} />
        <ContainerText mobileminheight="0">
          <GemContainer>
            <GemColumn1>
            <table>
            <tbody>
              <tr>
                <td><Heading>Luster</Heading><br />{Gem.node.data.lustre.text}<Divider><hr /></Divider><Heading>Colour</Heading><br />{Gem.node.data.colour.text}</td>
                <td><Heading>Origin</Heading><br />{Gem.node.data.origin.text}<Divider><hr /></Divider><Heading>Composition</Heading><br />{Gem.node.data.composition.text}</td>
                <td><Heading>Birthstone</Heading><br />{Gem.node.data.birthstone.text}<Divider><hr /></Divider><Heading>Fracture</Heading><br />{Gem.node.data.fracture.text}</td>
              </tr>
            </tbody>
          </table>
            </GemColumn1>
            <GemColumn2>
              <div>
              <video loop muted autoPlay playsInline>
                <source src={Gem.node.data.video.url} type="video/mp4" />
              </video>
              <br /><br />
              <GemTitle>{Gem.node.data.title.text}</GemTitle>
              </div>
            </GemColumn2>
            <GemColumn3>
              <DescriptionTitle>“{Gem.node.data.info_title.text}”</DescriptionTitle>
              <DescriptionText>{Gem.node.data.info_body.text}</DescriptionText>
            </GemColumn3>
          </GemContainer>
        </ContainerText>
        <GemWearWith tag={gemOpen.name} />
      </GemPopupContainer>
    )
  } else {
    return null;
  }
}

export default GemPopup

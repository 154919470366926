// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-page-template-js": () => import("/opt/buildhome/repo/src/templates/ProductPageTemplate.js" /* webpackChunkName: "component---src-templates-product-page-template-js" */),
  "component---src-templates-shop-page-template-js": () => import("/opt/buildhome/repo/src/templates/ShopPageTemplate.js" /* webpackChunkName: "component---src-templates-shop-page-template-js" */),
  "component---src-pages-404-js": () => import("/opt/buildhome/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/opt/buildhome/repo/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-collections-blessing-collection-index-js": () => import("/opt/buildhome/repo/src/pages/collections/blessing-collection/index.js" /* webpackChunkName: "component---src-pages-collections-blessing-collection-index-js" */),
  "component---src-pages-collections-blessing-collection-shop-index-js": () => import("/opt/buildhome/repo/src/pages/collections/blessing-collection/shop/index.js" /* webpackChunkName: "component---src-pages-collections-blessing-collection-shop-index-js" */),
  "component---src-pages-collections-camille-charriere-x-theo-index-js": () => import("/opt/buildhome/repo/src/pages/collections/camille-charriere-x-theo/index.js" /* webpackChunkName: "component---src-pages-collections-camille-charriere-x-theo-index-js" */),
  "component---src-pages-collections-camille-charriere-x-theo-shop-index-js": () => import("/opt/buildhome/repo/src/pages/collections/camille-charriere-x-theo/shop/index.js" /* webpackChunkName: "component---src-pages-collections-camille-charriere-x-theo-shop-index-js" */),
  "component---src-pages-collections-fortune-teller-index-js": () => import("/opt/buildhome/repo/src/pages/collections/fortune-teller/index.js" /* webpackChunkName: "component---src-pages-collections-fortune-teller-index-js" */),
  "component---src-pages-collections-fortune-teller-shop-index-js": () => import("/opt/buildhome/repo/src/pages/collections/fortune-teller/shop/index.js" /* webpackChunkName: "component---src-pages-collections-fortune-teller-shop-index-js" */),
  "component---src-pages-collections-moonchild-index-js": () => import("/opt/buildhome/repo/src/pages/collections/moonchild/index.js" /* webpackChunkName: "component---src-pages-collections-moonchild-index-js" */),
  "component---src-pages-collections-moonchild-shop-index-js": () => import("/opt/buildhome/repo/src/pages/collections/moonchild/shop/index.js" /* webpackChunkName: "component---src-pages-collections-moonchild-shop-index-js" */),
  "component---src-pages-collections-shapeshifter-index-js": () => import("/opt/buildhome/repo/src/pages/collections/shapeshifter/index.js" /* webpackChunkName: "component---src-pages-collections-shapeshifter-index-js" */),
  "component---src-pages-collections-shapeshifter-shop-index-js": () => import("/opt/buildhome/repo/src/pages/collections/shapeshifter/shop/index.js" /* webpackChunkName: "component---src-pages-collections-shapeshifter-shop-index-js" */),
  "component---src-pages-collections-victoriana-index-js": () => import("/opt/buildhome/repo/src/pages/collections/victoriana/index.js" /* webpackChunkName: "component---src-pages-collections-victoriana-index-js" */),
  "component---src-pages-collections-victoriana-shop-index-js": () => import("/opt/buildhome/repo/src/pages/collections/victoriana/shop/index.js" /* webpackChunkName: "component---src-pages-collections-victoriana-shop-index-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/buildhome/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-gallery-index-js": () => import("/opt/buildhome/repo/src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-gemstones-amethyst-index-js": () => import("/opt/buildhome/repo/src/pages/gemstones/amethyst/index.js" /* webpackChunkName: "component---src-pages-gemstones-amethyst-index-js" */),
  "component---src-pages-gemstones-labradorite-index-js": () => import("/opt/buildhome/repo/src/pages/gemstones/labradorite/index.js" /* webpackChunkName: "component---src-pages-gemstones-labradorite-index-js" */),
  "component---src-pages-gemstones-lapis-lazuli-index-js": () => import("/opt/buildhome/repo/src/pages/gemstones/lapis-lazuli/index.js" /* webpackChunkName: "component---src-pages-gemstones-lapis-lazuli-index-js" */),
  "component---src-pages-gemstones-moonstone-index-js": () => import("/opt/buildhome/repo/src/pages/gemstones/moonstone/index.js" /* webpackChunkName: "component---src-pages-gemstones-moonstone-index-js" */),
  "component---src-pages-gemstones-ruby-index-js": () => import("/opt/buildhome/repo/src/pages/gemstones/ruby/index.js" /* webpackChunkName: "component---src-pages-gemstones-ruby-index-js" */),
  "component---src-pages-gemstones-tiger-eye-index-js": () => import("/opt/buildhome/repo/src/pages/gemstones/tiger-eye/index.js" /* webpackChunkName: "component---src-pages-gemstones-tiger-eye-index-js" */),
  "component---src-pages-index-js": () => import("/opt/buildhome/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-index-js": () => import("/opt/buildhome/repo/src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-search-index-js": () => import("/opt/buildhome/repo/src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-shipping-returns-index-js": () => import("/opt/buildhome/repo/src/pages/shipping-returns/index.js" /* webpackChunkName: "component---src-pages-shipping-returns-index-js" */),
  "component---src-pages-shop-index-js": () => import("/opt/buildhome/repo/src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shopping-cart-index-js": () => import("/opt/buildhome/repo/src/pages/shopping-cart/index.js" /* webpackChunkName: "component---src-pages-shopping-cart-index-js" */),
  "component---src-pages-stockists-index-js": () => import("/opt/buildhome/repo/src/pages/stockists/index.js" /* webpackChunkName: "component---src-pages-stockists-index-js" */),
  "component---src-pages-terms-index-js": () => import("/opt/buildhome/repo/src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */)
}


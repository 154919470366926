import React from 'react';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import { Link } from 'gatsby';
import Close from '../../images/x.png'

const CookieWrapper = styled.div`
  position: fixed;
  background-color: #fff;
  bottom: 20px;
  left: 30px;
  width: 360px;
  height: 120px;
  padding: 30px;
  align-items: center;
  display: flex;
  border: 1px solid #ACA8A1;
  display: none;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  transition-delay: 3s;
  z-index: 999999 !important;
  @media (max-width: 768px) {
    bottom: auto;
    top: 130px;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    padding: 15px;
  }
  &.open {
    opacity: 1;
    display: flex;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.text_color};
    font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
  }
  p {
    @media (max-width: 768px) {
      padding: 0px;
      span {
        display: block;
        padding: 20px;
      }
    }
  }
`

const CloseImg = styled.img`
  float: right;
  width: 16px;
  height: 16px;
  cursor: pointer;
`

class Cookie extends React.Component {

  componentDidMount() {
    const agreed = JSON.parse(localStorage.getItem('cookieagree'));
    if(agreed === 'yes') {
      this.setState({ visible: false });
    } else  {
      this.setState({ visible: true });
    }
  }

  constructor(props){

    let cookieagree;
    super(props);

    this.state = { visible: cookieagree === 'yes' ? false : true };
    this.hideCookie = this.hideCookie.bind(this);
  }

  hideCookie() {
    localStorage.setItem('cookieagree', JSON.stringify('yes'))
    this.setState({visible: false});
  }

  render() {
    return(
      <CookieWrapper className={this.state.visible ? 'open' : ''}>
        <p><CloseImg src={Close} onClick={this.hideCookie} /><span>We use cookies on our website to give you the best service possible. Read more <Link to='/privacy'>here</Link>.</span></p>
      </CookieWrapper>
    )
  }
}

export default Cookie

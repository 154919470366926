export const Gemstones = [
  {
    name: 'Amethyst',
    url: '/gemstones/amethyst',
    still1: 'amethyst_white-1.jpg',
    still2: 'amethyst_white-2.jpg',
    image: 'amethyst.svg',
    video: 'amethyst_white.mp4',
    video_light: 'amethyst_white.mp4',
    lustre: 'Vitreous',
    origin: 'Brazil, Zambia, Bolivia, Canada, Uruguay, France',
    birthstone: 'February',
    colour: 'Violet Purple',
    composition: 'SiO₂',
    hardness: '7–lower in impure varieties',
    fracture: 'Conchoidal',
    info_title: 'Amethyst is a violet variety of quartz',
    info_body: 'Amethyst is a stone of spiritual protection and purification, cleansing one\'s energy field of negative influences and attachments, and creating a resonant shield of spiritual Light around the body. It acts as a barrier against lower energies, psychic attack, geopathic stress and unhealthy environments. Third Eye Chakra - uniqueness, strength, positivity, protection, transformation.'
  },
  {
    name: 'Black Onyx',
    url: '/gemstones/black-onyx',
    image: 'black_onyx.svg'
  },
  {
    name: 'Blue Sapphire',
    url: '/gemstones/blue-sapphire',
    image: 'blue_sapphire.svg'
  },
  {
    name: 'Cubic Zirconia',
    url: '/gemstones/cubic-zirconia',
    image: 'cubic_zirconia.svg'
  },
  {
    name: 'Diamond',
    url: '/gemstones/diamond',
    image: 'diamond.svg'
  },
  {
    name: 'Emerald',
    url: '/gemstones/emerald',
    image: 'emerald.svg'
  },
  {
    name: 'Garnet',
    url: '/gemstones/garnet',
    image: 'garnet.svg'
  },
  {
    name: 'Labradorite',
    url: '/gemstones/labradorite',
    image: 'labradorite.svg',
    video: 'labradorite_white.mp4',
    still1: 'labradorite_white-1.jpg',
    still2: 'labradorite_white-2.jpg',
    video_light: 'labradorite_white.mp4',
    lustre: 'Vitreous',
    origin: 'Madagascar, Canada',
    birthstone: 'Gemini',
    colour: 'Vitreous to pearly on cleavages',
    composition: '(Ca,Na)(Al,Si)₄O₈',
    hardness: '6 – 6,5',
    fracture: 'Uneven, Splintery',
    info_title: 'Labradorite a feldspar mineral',
    info_body: 'It often displays a beautiful iridescent play of colors, which can move as the stone is rotated. Labradorite gemstones usually have a dark base color with metallic-looking color plays of blue, green, yellow, and red. This iridescent effect is commonly known as labradorescence, and is named after this stone. Throat Chakra - Serenity, peace, listening.'
  },
  {
    name: 'Lapis Lazuli',
    url: '/gemstones/lapis-lazuli',
    image: 'lapis_lazuli.svg',
    video: 'lapis_white.mp4',
    still1: 'lapis_white-1.jpg',
    still2: 'lapis_white-2.jpg',
    video_light: 'lapis_white.mp4',
    lustre: 'Dull',
    origin: 'Afghanistan, Tadjikistan, Chili, Russia, Myanmar',
    birthstone: 'December',
    colour: 'Ultramarine blue, white veins, spots with metallic cluster, black veins',
    composition: '(Na,Ca)₈Al₆Si₆O₂₄ (S,SO)₄',
    hardness: '5 – 5.5',
    fracture: 'Uneven-Conchoidal',
    info_title: 'Lapis lazuli is a deep blue metamorphic rock used as a semi-precious stone.',
    info_body: 'At the end of the Middle Ages, lapis lazuli began to be exported to Europe, where it was ground into powder and made into ultramarine, the finest and most expensive of all blue pigments. It was used by some of the most important artists of the Renaissance and Baroque and was often reserved for the clothing of the central figures of their paintings, especially the Virgin Mary. Brow Chakra - Spiritual awareness, intuition, inner wisdom.'
  },
  {
    name: 'Malachite',
    url: '/gemstones/malachite',
    image: 'malachite.svg'
  },
  {
    name: 'Moonstone',
    url: '/gemstones/moonstone',
    image: 'moonstone.svg',
    video: 'moonstone_white.mp4',
    video_light: 'moonstone_white.mp4',
    still1: 'moonstone_white-1.jpg',
    still2: 'moonstone_white-2.jpg',
    lustre: 'Pearly',
    origin: 'Sri Lanka, India, United States America, Brazil, Australia',
    birthstone: 'June',
    colour: 'Colourless, grey, brown, orange, green, pink, blue, white',
    composition: '(Na,K)AlSi₃O₈',
    hardness: '6',
    fracture: 'Uneven to Conchoidal',
    info_title: 'Moonstone is a sodium potassium aluminium silicate',
    info_body: 'Moonstone is a variety of the feldspar group mineral orthoclase. During formation, orthoclase and albite separate into alternating layers. When light falls between these thin layers it is scattered producing the phenomenon called adularescence. Adularescence is the light that appears to billow across a gem. Crown Chakra - love, dreams, intuition, awareness.'
  },
  {
    name: 'Mother of Pearl',
    url: '/gemstones/mother-of-pearl',
    image: 'mother_of_pearl.svg'
  },
  {
    name: 'Opal',
    url: '/gemstones/opal',
    image: 'opal.svg'
  },
  {
    name: 'Pink Sapphire',
    url: '/gemstones/pink-sapphire',
    image: 'pink_sapphire.svg'
  },
  {
    name: 'Quartz',
    url: '/gemstones/quartz',
    image: 'quartz.svg'
  },
  {
    name: 'Ruby',
    url: '/gemstones/ruby',
    image: 'ruby.svg',
    video: 'ruby_white.mp4',
    video_light: 'ruby_white.mp4',
    still1: 'ruby_white-1.jpg',
    still2: 'ruby_white-2.jpg',
    lustre: 'Vitreous, Subadamantine',
    origin: 'Sri Lanka, India, United States America, Brazil, Australia',
    birthstone: 'June',
    colour: 'Pigeons blood to beef blood red',
    composition: 'Al₂O₃',
    hardness: '9',
    fracture: 'Conchoidal, splintery',
    info_title: 'A Ruby is a pink to blood-red coloured gemstone',
    info_body: 'The color of Ruby ranges from bright red to dark reddish-brown. The most preferred color is a deep blood red with a slightly bluish hue. Such Ruby is known as "Burmese Ruby" or "Pigeon\'s Blood Ruby". Ruby from Burma is famous for its exceptional colouring, and has traditionally produced the finest Rubies. Root Chakra - heath, purification, love.'
  },
  {
    name: 'Rose Quartz',
    url: '/gemstones/rose-quartz',
    image: 'rose_quartz.svg'
  },
  {
    name: 'Sapphire',
    url: '/gemstones/blue-sapphire',
    image: 'blue_sapphire.svg'
  },
  {
    name: 'Tiger Eye',
    url: '/gemstones/tiger-eye',
    image: 'tiger_eye.svg',
    video: 'tigereye_white.mp4',
    still1: 'tigereye_white-1.jpg',
    still2: 'tigereye_white-2.jpg',
    video_light: 'tigereye_white.mp4',
    lustre: 'Silky, Vitreous',
    origin: 'Australia, Burma, India, Namibia, South Africa, the United States America, Brazil, Canada, China, Korea, Spain',
    birthstone: 'Gemini',
    colour: 'Amber to brown',
    composition: 'SiO₂',
    hardness: '5.5 - 6',
    fracture: 'Fiberous',
    info_title: 'Tiger Eye is a chatoyant gemstone',
    info_body: 'As members of the quartz group, Tiger Eye and the related blue-coloured mineral hawk\'s eye gain their silky, lustrous appearance from the parallel intergrowth of quartz crystals and altered amphibole fibres that have mostly turned into limonite. Sacral Chakra - Creativity, intuition, sexuality.'
  },
  {
    name: 'Tourmaline',
    url: '/gemstones/tourmaline',
    image: 'tourmaline.svg'
  },
  {
    name: 'Turquoise',
    url: '/gemstones/turquoise',
    image: 'turquoise.svg'
  },
  {
    name: 'White Sapphire',
    url: '/gemstones/white-sapphire',
    image: 'white_sapphire.svg'
  },
  {
    name: 'Yellow Sapphire',
    url: '/gemstones/yellow-sapphire',
    image: 'yellow_sapphire.svg'
  },
  {
    name: 'Zircon',
    url: '/gemstones/zircon',
    image: 'zircon.svg'
  },
]

import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import { media } from 'styled-bootstrap-grid2'
import { Location } from '@reach/router'
import imageLogo from '../../images/tw_logo.png'
import { Link, navigate, graphql, StaticQuery } from 'gatsby'
import CartIcon from './cart-icon.png'
import CartMenu from '../cart_menu';
import { Collections } from '../../collections';

const HeaderElement = styled.header`
  padding: 30px;
  z-index: 1000;
  position: fixed;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0px;
    height: 99px;
    text-align: center;
    background-color: #F8F7F6;
    z-index: 1000;
  }
`

const BurgerContainer = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 60px;
    height: 100px;
  }
`

const Burger = styled.div`
  width: 23px;
  height: 10px;
  height: ${(props) => props.menuOpen ? "1px" : "10px"};
  transform: translateY(${(props) => props.menuOpen ? "3px" : "0px"});
  border-top: 1px solid black;
  border-bottom: ${(props) => props.menuOpen ? "1px solid white" : "1px solid black"};
  margin-left: auto;
  transition: all 0.25s ease-in-out;
  margin-right: auto;
  margin-top: 44px;
`

const SymbolContainer = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 60px;
    height: 100px;
  }
  img {
    width: 22px;
    margin-top: 38px;
  }
`

const LogoContainer = styled.div`
  height: 100%;
  position: relative;
  @media (max-width: 1024px) {
    background-color: #F8F7F6;
    z-index: 1000;
    border-bottom: 1px solid #3C3C3B;
  }
`

const SearchForm = styled.form`
  input {
    border: none;
    background-color: transparent;
    font-size: 11.8px;
    text-rendering: geometricPrecision;
    font-weight: 400;
    @media (max-width: 1024px) {
      font-size: 13px;
      font-weight: normal;
    }
  }
  input[type="submit"] {
    display: none;
    outline: 0;
    &.visible {
      outline: 0;
      display: inline-block;
    }
  }
  input[type="text"] {
    width: 100px;
    font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
    outline: 0;
    border-bottom: 1px solid transparent;
    padding-bottom: 3px;
    &::placeholder {
      color: ${props => props.theme.link_color};
      text-transform: uppercase;
    }
    &:focus {
      outline: 0;
      font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
      font-style: italic;
      border-bottom: 1px solid ${props => props.theme.grey};
      color: ${props => props.theme.text_color} !important;
      text-transform: none !important;
    }
  }
`

const Logo = styled.img`
  width: 180px;
  margin-bottom: 10px;
  height: auto;
  @media (max-width: 1024px) {
    width: 200px;
    padding-top: 44px;
    height: auto;
    padding-bottom: 44px;
  }
`

const slideDown = (props) => keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const Overlay = styled.div`
  display: none;
  &.open {
    @media (max-width: 1024px) {
      z-index: 0;
      display: block;
      position: fixed;
      height: 100vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`

const Nav = styled.nav`
  @media (max-width: 1024px) {
    transition: all 0.3s ease-in-out;
    transform: translateX(-300px);
    ul {
      margin-top: 0px;
      opacity: 0;
    }
  }
  &.open {
    @media (max-width: 1024px) {
      height: calc(100vh - 90px);
      transform: translateX(0px);
      border-right: 1px solid #3C3C3B;
      width: 300px;
      background-color: #F8F7F6;
      z-index: 10;
      padding-top: 50px;
      ul {
        padding-left: 15px;
        margin-bottom: 10px;
        animation: ${slideDown} 250ms both;
        animation-delay: 0.25s;
        li {
          margin-bottom: 20px;
          text-align: left;
          &:last-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
`

const NavList = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin-top: 14px;
`

const SubNavList = styled.ul`
  list-style: none;
  padding-left: 0px;
  height: 0px;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  li {
    text-transform: none;
  }
  &.open {
    height: auto;
    overflow: visible;
    margin-top: 1rem;
    margin-bottom: 1rem;
    li a {
      color: ${props => props.theme.text_color};
      &.active {
        color: ${props => props.theme.link_color};
      }
    }
  }
`

const NavItem = styled.li`
  text-transform: uppercase;
  font-size: ${props => props.theme.menu.fontsize};
  line-height: ${props => props.theme.menu.lineheight};
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    font-size: 13px;
    line-height: 18px;
  }
  span {
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.headline_color};
  }
`
const stripTrailingSlash = (str) => {
  return str.endsWith('/') ?
      str.slice(0, -1) :
      str;
};

const shop_urls = ['/shop', '/earrings', '/necklaces', '/rings', '/bracelets', '/cufflinks', '/anklets', '/crystal-dishes'];
const information_urls = ['/contact','/stockists','/shipping-returns'];
let shop_visible;

class Header extends Component {

  constructor(props) {

    super(props);
    this.toggleBurger = this.toggleBurger.bind(this);
    this.toggleCollection = this.toggleCollection.bind(this);
    this.toggleShop = this.toggleShop.bind(this);
    this.toggleInformation = this.toggleInformation.bind(this);
    this.openSiderbar = this.openSiderbar.bind(this);
    this.gotoAbout = this.gotoAbout.bind(this);
    this.gotoContact = this.gotoContact.bind(this);
    this.gotoGallery = this.gotoGallery.bind(this);
    this.gotoCamille = this.gotoCamille.bind(this);
    this.searchFocus = this.searchFocus.bind(this);
    this.searchUnfocus = this.searchUnfocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    const current_location = stripTrailingSlash(this.props.location.pathname);

    shop_visible = shop_urls.indexOf(current_location) > -1 ? true : false;
    let information_visible = information_urls.indexOf(this.props.location.pathname) > -1 ? true : false;

    this.state = {
      collectionsOpen: false,
      informationOpen: information_visible,
      shopOpen: shop_visible,
      searchActive: false,
      search: '',
      placeholder: 'Search',
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    if(this.state.search.length > 2) {
      this.setState({menuOpen: false}, function() {
        navigate('/search', { state: {search: this.state.search} })
        this.setState({
          searchActive: false,
          search: '', placeholder: 'Search',
          shopOpen: false,
          collectionsOpen: false,
          informationOpen: false,
        });
        this.props.toggleMenu();
      })
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.location.href != prevProps.location.href) {
      if(this.props.location.state !== null && this.props.location.state.shopOpen != this.state.shopOpen) {
        this.setState({shopOpen:this.props.location.state.shopOpen }); // via state
      } else {
        this.setState({shopOpen:shop_visible }); // via url, initial load
      }
    }
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  searchFocus() {
    this.setState({searchActive: true, placeholder: ''});
  }

  searchUnfocus() {
    this.setState({searchActive: false, search: '', placeholder: 'Search'});
  }

  toggleBurger() {
    this.setState({menuOpen: !this.state.menuOpen});
    this.props.toggleMenu();
  }

  gotoCamille(e) {
    e.preventDefault();
    this.setState({collectionsOpen: false, shopOpen: false, informationOpen: false});
    this.toggleBurger();
    navigate('/collections/camille-charriere-x-theo/shop/');
  }

  gotoContact(e) {
    e.preventDefault();
    this.setState({collectionsOpen: false, shopOpen: false, informationOpen: false});
    this.toggleBurger();
    navigate('/contact');
  }

  gotoGallery(e) {
    e.preventDefault();
    this.setState({collectionsOpen: false, shopOpen: false, informationOpen: false});
    this.toggleBurger();
    navigate('/gallery');
  }

  gotoAbout(e) {
    e.preventDefault();
    this.setState({collectionsOpen: false, shopOpen: false, informationOpen: false});
    this.toggleBurger();
    navigate('/about');
  }

  toggleCollection(e) {
    e.preventDefault();
    this.setState({collectionsOpen: !this.state.collectionsOpen, shopOpen: false, informationOpen: false});
  }

  toggleShop(e) {
    e.preventDefault();
    this.setState({shopOpen: !this.state.shopOpen, informationOpen: false, collectionsOpen: false});
  }

  toggleInformation(e) {
    e.preventDefault();
      this.setState({collectionsOpen: false, shopOpen: false, informationOpen: !this.state.informationOpen});
  }

  openSiderbar(location) {
    if(location.indexOf('/collections/') !== -1 && this.state.collectionsOpen === false) {
      this.setState({shopOpen: false, informationOpen: false, collectionsOpen: true, menuOpen: false});
      this.props.toggleMenu();
    }
  }

  componentDidMount() {
    this.openSiderbar(this.props.location.pathname);
    // // close shop sidebar when coming from product listing page via state
    // if(typeof window !== 'undefined' &&
    //     window.history.state !== null &&
    //     window.history.state.shopOpen !== 'undefined' &&
    //     window.history.state.shopOpen === false &&
    //     this.state.shopOpen === true
    //     ) {
    //   this.setState({shopOpen: false});
    // }
  }

  render() {

    const { location } = this.props;
    const { collectionsOpen, informationOpen, shopOpen } = this.state;

    // if (typeof window !== `undefined`) {

    //   if(location.state !== null && location.state.shopOpen !== shopOpen) {
    //     shopOpen = location.state.shopOpen;
    //   } else {
    //     shopOpen = this.state.shopOpen;
    //   }

    // } else {

    //   if(location.state !== undefined && location.state.shopOpen !== shopOpen) {
    //     shopOpen = location.state.shopOpen;
    //   } else {
    //     shopOpen = this.state.shopOpen;
    //   }
    // }

    // console.log(this.props.shoplinks.shop_links);

    return (
      <HeaderElement className={this.props.isOpen ? 'open' : ''}>
        <LogoContainer>
          <BurgerContainer onClick={this.props.toggleMenu} menuOpen={this.props.isOpen}>
            <Burger menuOpen={this.props.isOpen} />
          </BurgerContainer>
          <Link to="/"><Logo src={imageLogo} alt="Theodora Warre" /></Link>
          <SymbolContainer><CartMenu /></SymbolContainer>
        </LogoContainer>

        <Overlay className={this.props.isOpen ? 'open' : ''} onClick={this.props.toggleMenu}></Overlay>

        <Nav className={this.props.isOpen ? 'open' : ''}>
          <NavList>
            <NavItem><span onClick={this.toggleShop}>Shop</span>
                <SubNavList className={this.state.shopOpen === true ? 'open' : ''}>
                  <NavItem><Link onClick={this.props.toggleMenu} state={{ shopOpen: true, collectionsOpen: false, informationOpen: false }} activeClassName="active" to="/shop">All</Link></NavItem>
                  {
                    this.props.shoplinks.shop_links.map((link, index) => {
                      return(
                        <NavItem key={index}>
                          <Link onClick={this.props.toggleMenu}
                                state={{ shopOpen: true, collectionsOpen: false, informationOpen: false }}
                                activeClassName="active"
                                to={link.menu_link.text}>{link.menu_text.text}</Link>
                        </NavItem>
                      )
                    })
                  }
                </SubNavList>
            </NavItem>
            <NavItem><span onClick={this.gotoGallery}>Gallery</span></NavItem>
            <NavItem><NavItem><span onClick={this.gotoContact}>Contact</span></NavItem></NavItem>
            <NavItem>
                <SearchForm onSubmit={this.handleSubmit}>
                  <input name="search" autoFocus={false} type="text" placeholder={this.state.placeholder} value={this.state.search} onChange={this.handleChange} onFocus={this.searchFocus} />
                  <input type="submit" className={this.state.searchActive ? 'visible' : ''} value="→" onClick={this.handleSubmit} />
                </SearchForm>
            </NavItem>
            <NavItem><span onClick={this.gotoAbout}>About</span></NavItem>
          </NavList>
        </Nav>

      </HeaderElement>
    );
  }
}

// export default props => (
//   <Location {...props}>
//     {locationProps => <Header {...locationProps} {...props} />}
//   </Location>
// );


export default props => (
  <StaticQuery
  query={graphql`
  query CollectionsPrismic {
    prismicCollectionIndex {
      data {
        collections {
          collection {
            id
            slug
            url
            document {
              data {
                in_menu
                collection_description {
                  text
                }
                collection_shop_url {
                  text
                }
                collection_url {
                  text
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
    prismicShopMenu {
      data {
        shop_links {
          menu_link {
            text
          }
          menu_text {
            text
          }
          product_filter
        }
      }
    }
  }
  `}
  render={data => <Header shoplinks={data.prismicShopMenu.data} collections={data.prismicCollectionIndex.data} {...props} />}
  />
)

import React, { useContext } from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid2'
import cart_menu_icon from './cart_menu_icon_empty.png'
import StoreContext from '../../context/StoreContext'

const ItemNum = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-image: url(${cart_menu_icon});
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: bottom;
  margin-left: 10px;
  transform: translateY(2px);
  color: #9B9790;
  @media (max-width: 768px) {
    transform: translateY(10px);
  }
`

const CartNav = styled.nav`
  right: 30px;
  top: 24px;
  position: fixed;
  z-index: 1000;
  @media (max-width: 768px) {
    right: 28px;
    margin-right: 2px;
  }
  span {
    @media (max-width: 768px) {
      display: none;
    }
  }
  ${media.md`
    display: block;
  `}
  ul {
    list-style: none;
    li {
      display: inline-block;
      text-transform: uppercase;
      margin-left: 20px;
      img {
        height: 20px;
        vertical-align: bottom;
        padding-left: 10px;
        display: inline-block;
        transform: translateY(2px);
      }
      a {
        color: ${props => props.theme.link_color};
        text-decoration: none;
      }
    }
  }
`

const CartMenu = () => {
  const context = useContext(StoreContext)
  const { checkout } = context
  const numItems = checkout.lineItems.length ? checkout.lineItems.length : '0';
  return(
    <CartNav>
      <ul>
        <li><a href="/shopping-cart"><span>Cart</span> <ItemNum>{numItems}</ItemNum></a></li>
      </ul>
    </CartNav>
  )
}

export default CartMenu;

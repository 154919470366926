import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import MailchimpSignup from '../mailchimp'

const FooterElement = styled.footer`
  border-top: 1px solid #9A9998;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  transform: translateY(-3px);
  @media (max-width: 768px) {
    padding-bottom: 16px;
  }
`
const List = styled.ul`
  list-style: none;
  padding-left: 0px;
  li {
  display: inline-block;
  margin-right: 14px;
    a {
      text-decoration: none;
      color: ${props => props.theme.link_color};
    }
  }
`

const Desktop = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`
const Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    ul.newsletter {
      padding-bottom: 16px;
      border-bottom: 1px solid #C9C7C2;
      margin-bottom: 16px;
    }
    ul li {
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
      a {
        color: #000;
        text-decoration: none;
      }
    }
  }
`

const LeftLinks = styled.div`
  float: left;
  max-width: 30%;
  padding-left: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`

const MiddleLinks = styled.div`
  max-width: 30%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    display: none;
  }
`

const RightLinks = styled.div`
  float: right;
  max-width: 30%;
  padding-right: 40px;
  @media (max-width: 768px) {
    display: none;
  }
  li {
    text-align: right;
    margin-right: 0px;
  }
`

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = { newsletter: false };
    this.toggleNewsletter = this.toggleNewsletter.bind(this);
  }

  toggleNewsletter(e) {
    e.preventDefault();
    this.setState({newsletter: !this.state.newsletter});
  }

  render() {

    const rightLinkText = this.props.location.pathname.startsWith('/contact') ? 'Site by The Digital Fairy' : 'Instagram';
    const rightLinkURL = this.props.location.pathname.startsWith('/contact') ? 'http://www.thedigitalfairy.co.uk/' : 'https://www.instagram.com/theodorawarre/?hl=en';

    return (
      <>
        <FooterElement>
          <Mobile>
            <ul className="newsletter">
              <li><a href="#" onClick={(e) => this.toggleNewsletter(e)}>Sign up to our <i>Newsletter</i></a></li>
            </ul>
          </Mobile>
          <Desktop>
          <LeftLinks>
            <List>
              <li><Link to="/terms">Terms</Link></li>
              <li><Link to="/privacy">Privacy</Link></li>
            </List>
          </LeftLinks>
          <RightLinks>
            <List>
              <li><a href={rightLinkURL} rel="noopener noreferrer" target="_blank">{rightLinkText}</a></li>
            </List>
          </RightLinks>
          <MiddleLinks><List><li><a href="#" onClick={(e) => this.toggleNewsletter(e)}>Sign up to our <i>Newsletter</i></a></li></List></MiddleLinks>
          </Desktop>
          <Mobile>
            <ul>
              <li><Link to="/terms">Terms</Link></li>
              <li><Link to="/privacy">Privacy</Link></li>
              <li><a href={rightLinkURL} rel="noopener noreferrer" target="_blank">{rightLinkText}</a></li>
            </ul>
          </Mobile>
        </FooterElement>
        <MailchimpSignup open={this.state.newsletter} toggle={this.toggleNewsletter} />
      </>
    );
  }
}

export default Footer;

import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import styled from 'styled-components';
import { Link } from 'gatsby'
import x from '../../images/newsletter_x.svg';
import addToMailchimp from 'gatsby-plugin-mailchimp'

const MailchimpContainer = styled.div`
  width: 100vw;
  transform: translateY(400px);
  opacity: 0;
  position: fixed;
  bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  transition:all 0.3s ease-in-out;
  @media (max-width: 768px) {

  }
  & > div {
    width: 560px;
    margin-left: auto;
    margin-right: auto;
    background-color: #F8F7F6;
    min-height: 200px;
    text-align: center;
    padding: 40px;
    position: relative;
    @media (max-width: 768px) {
      width: 100vw;
    }
  }
  &.open {
    transform: translateY(0px);
    opacity: 1;
  }
  input[type='text'] {
    border-bottom: 1px solid ${props => props.theme.grey};
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: transparent;
    width: 100%;
    text-align: center;
    font-style: italic;
    font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
    margin-top: 2rem;
    margin-bottom: 2rem;
    outline: 0;
    &:focus {
      outline: 0;
    }
  }
  input[type='submit'] {
    font-size: 13px;
    border: none;
    background-color: transparent;
    margin-top: 20px;
    cursor: pointer;
    font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
    outline: 0;
    &:focus {
      outline: 0;
    }
  }
  label {
    text-align: left;
  }
  img {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`

const CheckboxContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;

  label {
    display: block;
    padding-left: 1.5em;
    text-indent: -.7em;
    a {
      text-decoration: none;
      color: #3C3C3B;
      font-style: italic;
      font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
    }
  }

  input[type=checkbox] {
    display:none;
  }

  input[type=checkbox] + label:before {
    display: inline-block;
    content: "□";
    letter-spacing: 10px;
    cursor: pointer;
    font-size: 18px;
  }

  input[type=checkbox]:checked + label:before {
    content: "■";
    cursor: pointer;
    letter-spacing: 10px;
    font-size: 18px;
  }

  input[type=checkbox]:checked + label:before {
    letter-spacing: 8px;
  }
`

const Title = styled.h3`
  text-transform:none;
`

class MailchimpSignup extends Component {

  constructor(props) {
    super(props);
    this.state = { email: '', disabled: true, updatesChecked: false, termsChecked: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleCheckboxChange(event) {

    if(event.target.id === 'updates') {
      this.setState({updatesChecked: !this.state.updatesChecked});
    }

    if(event.target.id === 'terms') {
      this.setState({termsChecked: !this.state.termsChecked}, function() {
        if(this.state.email.length > 5 && this.validateEmail(this.state.email) && this.state.termsChecked === true) {
          this.setState({disabled: false});
        } else {
          this.setState({disabled: true});
        }
      });
    }

  }

  handleChange(event) {
    this.setState({email: event.target.value});
    if(this.state.email.length > 5 && this.validateEmail(this.state.email) && this.state.termsChecked === true) {
      this.setState({disabled: false});
    }
  }

  handleSubmit(e) {
    alert('A name was submitted: ' + this.state.email);
    e.preventDefault();
    addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
    .then(data => {
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      // console.log(data)
      if(data.result == 'success') {
        this.props.toggle();
      }
    })
    .catch(() => {
      // unnecessary because Mailchimp only ever
      // returns a 200 status code
      // see below for how to handle errors
    })
  }

  render() {

    const cookies = new Cookies();
    cookies.set('mailchimp', 'seenit', { path: '/' });
    // console.log(cookies.get('mailchimp')); // seenit

    return (
      <MailchimpContainer className={this.props.open ? 'open' : ''}>
        <div>
          <img src={x} alt="close" onClick={this.props.toggle} />
          <Title>Sign up to our Newsletter</Title>
          <form onSubmit={this.handleSubmit}>
            <input type="text" name="email" placeholder="email address" onChange={this.handleChange}value={this.state.email} />
            <br />
            <CheckboxContainer>
              <input onChange={this.handleCheckboxChange} id='updates' type="checkbox" checked={this.state.updatesChecked} />
              <label htmlFor='updates'>I would like to receive news, trend reports and updates from Theodora Warre.</label>
            </CheckboxContainer>

            <CheckboxContainer>
              <input onChange={this.handleCheckboxChange} id='terms' type="checkbox" checked={this.state.termsChecked} />
              <label htmlFor='terms'>I have read and understood Theodorra C's <Link to='/terms'>Terms and Conditions</Link> & <Link to='/privacy'>Privacy Policy</Link></label>
            </CheckboxContainer>
            <br />
            <input disabled={this.state.disabled} type="submit" value="Subscribe →" />
            <br />
          </form>
        </div>
      </MailchimpContainer>
    );
  }
}

export default MailchimpSignup;

import React from 'react'
import Client from 'shopify-buy'

const client = Client.buildClient({
  storefrontAccessToken: 'a6af8db352e8f56721d818825614a625',
  domain: 'theodora-warre.myshopify.com',
})

export const defaultStoreContext = {
  client,
  adding: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  letter: '',
  activesection: '',
  gemOpen: {visible: false},
  careVisible: false,
  toggleCare:() => {},
  shareVisible: false,
  toggleShare:() => {},
  togglePreorder:() => {},
  setActiveSection: () => {},
  toggleGem: () => {},
  selectLetter: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
}

const StoreContext = React.createContext(defaultStoreContext)

export default StoreContext
